import { instanceTrade } from '../../apiBase/instanceTrade';
var authToken:any = localStorage.getItem('authToken');

export const TradeApi = {

	getTokenList(chainId:any) {
		return instanceTrade.get<any>(`api/tokens/by-chain/${chainId}`, {});
	},
	
	getSetting() {
		return instanceTrade.get<any>(`api/settings`, {});
	},

	getOrderById(orderId:any) {
		return instanceTrade.get<any>(`api/orders/detail/${orderId}`, {});
	},

	getOrdersByWallet(address:any, params:any) {
		return instanceTrade.get<any>(`api/orders/wallet/${address}`, {
			params
		});
	},
	getOrderBuyByWallet(address:any, params:any) {
		return instanceTrade.get<any>(`api/orders/wallet/${address}/buy-histories`, {
			params
		});
	},
	getOrderSellByWallet(address:any, params:any) {
		return instanceTrade.get<any>(`api/orders/wallet/${address}/sell-histories`, {
			params
		});
	},

	getPools() {
		return instanceTrade.get<any>(`api/pools`, {});
	},
	getPairInfo(tokenFrom:any, chainId:any) {
		return instanceTrade.get<any>(`api/pools/allow-pairs/token/${tokenFrom}/chain-id/${chainId}`, {});
	},
	getPairPrice(pairId:any) {
		return instanceTrade.get<any>(`api/orders/price/${pairId}`, {});
	},
	requestBuyToken(data:any) {
	
		return instanceTrade.post<any>(`api/users/order` ,data, {});
	},
	
};
