
import { Col, message, Tabs, Layout, Row, Tooltip, Input, Modal, Button } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'

import { BaseApi } from "../../config/api/BaseApi";
import Web3 from "web3";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import "../style.scss";

declare const window: Window &
  typeof globalThis & {
      trustwallet: any;
      ethereum: any;
      bitkeep: any;
      okexchain: any;
      safepalProvider: any;
  };

const LaunchingSoon = () => {

  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId
  const [isModalSucees, setIsModalSucees] = useState(false);
  const [stakingAmount, setStakingAmount]: any = useState('');
  const [wallet, setWallet]: any = useState('');

    let web3:any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

  if (connectorId === "io.metamask" || connectorId === "bsc") {
      web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
      web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
      web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
      web3 = new Web3(window.okexchain);
  } else {
      web3 = new Web3(window.ethereum);
  }

  
  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = e.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
          setStakingAmount(inputValue);
      }
  };


  const onSubmit = ()=>{
    setIsModalSucees(true)
  }
  const onSubmitFinal = async ()=>{
    if(wallet == '' || stakingAmount == 0){
      message.error('Invalid value!');
      return;
    }
    let isAddress = web3.utils.isAddress(wallet);
    if(!isAddress){
      message.error('Wallet invalid!');
      return;
    }
    if(stakingAmount <= 0 && stakingAmount > 100){
      message.error('Amount must be 01-99!');
      return;
    }
    let data:any = {
      "wallet": wallet,
      "amount": Number(stakingAmount),
    }
    await BaseApi.updateEst(data).then((res:any)=>{

      message.success('Successfully!');
      setStakingAmount(0);
      setWallet('');
      setIsModalSucees(false);

    }).catch((error:any)=>{
      message.error('Update Failed!');
    });
  }

  const handleChangeWallet = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = e.target;
      setWallet(inputValue);
  };

  return (
    <>
      <div className="app-page-nohead">
        <div className="lc-top">
          <div className="container">
            <div className="top-logo">
              <img src="../images/stable-hodl.svg"/>
            </div>
            <div className="desc">
              <span className="">High yield.</span>
              <span className="">Sustainable returns.</span>
              <span className="mt-10">Powered by HeLa.</span> 
            </div>
            <div className="info">
              <div className="text">
                'Can you estimate the APY received by early users of StableHodl last week?'
              </div>
              <div className="input">
                <Input 
                  size="large"
                  type="text"
                  value={stakingAmount}
                  onChange={handleChangeAmount}
                  addonBefore={<span className="text-white">%</span>}
                  addonAfter={
                    <>
                      <button disabled={stakingAmount == 0 || stakingAmount >= 100} className="btn-black" onClick={onSubmit}>Submit</button>
                    </>
                  } 
                  placeholder="01-99" 
                />
              </div>
              
            </div>
          </div>
          <div className="lc-bot-mb">
                LAUNCHING SOON
              </div>
        </div>
        <div className="lc-bot">
          LAUNCHING SOON
        </div>
      </div>
      <Modal footer={false} className="modal-cus" title={<><span className="text-white weight-500 font-20">Thank you for submitting your answer</span></>} open={isModalSucees} onOk={()=> setIsModalSucees(false)} onCancel={()=>  setIsModalSucees(false)}>
          <p className="text-white text-blue mt-0">The most exact or closest answer will get a secret reward. Follow StableHodl to find out more</p>
          <div className="share-info">
              <p className="mt-0 mb-10 text-green">Step 1</p>
              <a target="_blank" href="https://twitter.com/StableHodl">
                <div className="share-content">
                  <img src="../images/x2.svg" />
                  <span className="text-white font-16">Share your answer on X and tag @StableHodl</span>
                </div>
              </a>

              <p className="mt-15 mb-10 text-green">Step 2</p>
              <div className="share-content b-green bg-tran">
                  <img src="../images/bsc2.svg" />
                  <Input onChange={handleChangeWallet} value={wallet} placeholder="Enter your EVM chain wallet" />
              </div>
          </div>
          <Button disabled={wallet == '' || stakingAmount == 0} size="large" onClick={onSubmitFinal} htmlType="button" className="btn-green full-width">OK</Button>
          <div className="d-flex-center mt-20">
              <span className="text-white weight-500">Follow us on:</span>
              <div className="d-flex-end">
                  <a target="_blank" href="https://twitter.com/StableHodl"><img width={20} src="../images/x.svg" /></a>
                  <a target="_blank" href="https://discord.gg/cFA9AVVf"><img width={20} src="../images/dis.svg" /></a>
                  <a target="_blank" href="https://t.me/stablehodl_byhela"><img width={20} src="../images/tele.svg" /></a>
              </div>
          </div>
      </Modal>
    </>
  );
};
export default LaunchingSoon;
