import { message } from "antd";

const handleClose = () => {
  message.destroy();
};
const Content = (data) => {
  return (
    <div className="d-flex-center" onClick={handleClose}>
      <div>{data}</div>
    </div>
  );
};


export const notiSuccess = (content, duration) => {
  return new Promise((resolve) => {
    message.success({
      type: "success",
      content: Content(content),
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiWarning = (content, duration) => {
  return new Promise((resolve) => {
    message.warning({
      type: "warning",
      content: Content(content),
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiError = (content, duration) => {
  return new Promise((resolve) => {
    message.error({
      type: "error",
      content: Content(content),
      className: "custom-class",
      duration: duration,
    });
  });
};
