import React, { useState, useEffect } from "react";
import { Col, message, Row } from "antd";
import { Link } from "react-router-dom";

const Footer = () => {
  
  return (
    <>
      <div className="footer-main">
        <div className="container">
          <Row gutter={30}>
            <Col xs={24} sm={8}>
              <div className="d-flex-center-mobile mt-15-sp">
                <div className="text-main text-center-mb"><a className="text-white" target="_blank" href="https://docs.google.com/document/d/1WNBfDT8mdwy2LSR0alYToEKrFR5-ilMA5bhT59_s9Bo/edit">Terms & Conditions </a></div>
                <div className="d-flex-left-mobile mt-15-sp">
                  <span className="text-white">Audited by:</span> 
                  <img style={{ width: '150px' }} src="../images/logo_shield.png" /></div>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="text-center gap-15 d-flex-mid mt-15-sp">
                <a target="_blank" href="https://twitter.com/StableHodl">
                  <img width={23} src="../images/x.svg" />
                </a>
                <a target="_blank" href="https://discord.gg/stablehodl">
                  <img width={23} src="../images/dis.svg" />
                </a>
                <a target="_blank" href="https://t.me/stablehodl">
                  <img width={25} src="../images/tele.svg" />
                </a>
                <a target="_blank" href="https://medium.com/stablehodl">
                  <img width={30} src="../images/medium.svg" />
                </a>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <p className="text-main text-rightcenter-mb mt-15-sp">© 2024 StableHodl, Inc.</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Footer;
