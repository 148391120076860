
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row } from "antd";



declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };

    

const UserInfo = (prop:any) => {

    const { claimInfo } = prop;


    return (
        <>
        <Row gutter={30} justify="center">
            <Col xs={24} sm={12}>
                {/* <div className="point-sum-info height-auto mb-15">
                    <p className="mt-0 text-white weight-600">Stake Balance</p>
                    <p className="text-gray">USDT: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_amount) : 0}</span> ~ HLUSD: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_in_hlusd_amount) : 0}</span></p>
                    <p className="text-gray">USDC: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_amount) : 0}</span> ~ HLUSD: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_in_hlusd_amount) : 0}</span></p>
                </div> */}
                <div className="point-sum-info mb-15" style={{minHeight: '130px'}}>
                    <Row gutter={10 }>
                        <Col xs={6} sm={4}>
                            <div className="sum-info-img">
                                <img src="../images/info1.svg" />
                            </div>
                        </Col>
                        <Col xs={18} sm={20}>
                            <p className="mt-0 text-white weight-600">Stake Balance</p>
                            <p className="text-gray-light flex-btw">
                                <span>USDT: </span>
                                <span><span className="weight-500">{claimInfo && claimInfo?.usdt_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_in_hlusd_amount) : 0}</span> HLUSD </span>
                            </p>
                            <p className="text-gray-light flex-btw mb-0">
                                <span>USDC: </span>
                                <span><span className="weight-500">{claimInfo && claimInfo?.usdc_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_in_hlusd_amount) : 0}</span> HLUSD </span>
                            </p>

                            <p className="text-gray-light flex-btw mb-0">
                                <span>HLUSD: </span>
                                <span><span className="weight-500">{claimInfo && claimInfo?.native_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.native_in_hlusd_amount) : 0}</span> HLUSD </span>
                            </p>
                        </Col>
                    </Row>
                    
                </div>
            </Col>
            {/* <Col xs={24} sm={8}>
                <div className="point-sum-info height-auto mb-15">
                    <Row gutter={10}>
                        <Col xs={6} sm={4}>
                            <div className="sum-info-img">
                                <img src="../images/info2.svg" />
                            </div>
                        </Col>
                        <Col xs={18} sm={20}>
                            <p className="mt-0 text-white weight-600">Points</p>
                            <p className="text-white weight-600 mb-0"><span>{ claimInfo && userClaimed == false ? Number(claimInfo?.points).toFixed(0) : 0}</span></p>
                        </Col>
                    </Row>  
                </div>
            </Col> */}
            {/* <Col xs={24} sm={8}>
                <div className="point-sum-info mb-15" style={{minHeight: '103px'}}>
                    <p className="mt-0 text-white weight-600" style={{marginTop: '20px'}}>Yield Earned</p>
                    <p className="mb-0"><span className="text-white weight-600">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.reward) : 0}</span> <span className="text-gray-light">HLUSD</span></p>
                </div>
            </Col> */}
            <Col xs={24} sm={12}>
                <div className="point-sum-info mb-15" style={{minHeight: '130px'}}>
                    <p className="text-white weight-600 mt-0">Total Fund</p>
                    <p className="mb-0"><span className="text-white weight-600">{ claimInfo && Number(claimInfo?.hlusd_amount) > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.hlusd_amount) : 0 }</span> <span className="text-gray-light">HLUSD</span></p>
                </div>
            </Col>
        </Row>
            {/* <div className="point-info height-auto mb-15 mt-50-mb">
                <p>Stake Balance</p>
                <p className="text-gray">USDT: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_amount) : 0}</span> ~ HLUSD: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_in_hlusd_amount) : 0}</span></p>
                <p className="text-gray">USDC: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_amount) : 0}</span> ~ HLUSD: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_in_hlusd_amount) : 0}</span></p>
            </div>
            <div className="point-info height-auto mb-15">
                <p>USDT to Point</p>
                <p className="text-gray"><span className="weight-500">{configApy?.usd_to_point}</span></p>
            </div> */}
            {/* <div className="point-info height-auto mb-15">
                <p>Rewards</p>
                <p>HLUSD: <span className="weight-500">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.reward) : 0}</span></p>
            </div> */}
            {/* <div className="point-info height-auto mb-15">
                <p>Total laimable</p>
                <p>HLUSD: <span className="weight-500">{ claimInfo && Number(claimInfo?.claim_amount) > 0 ? new Intl.NumberFormat("ja-JP").format(rewardReal) : claimInfo ? new Intl.NumberFormat("ja-JP").format(rewardConvert) : 0 }</span></p>
            </div> */}
        </>
    );
};
export default UserInfo;
