import { BinanceWallet, Metamask, SafePalWallet, TrustWallet, WalletConnect , TokenPocket } from './icons';
export const NetworkContextName = "NETWORK";
// export const API_URL = "http://localhost:8080/";
export const API_URL = process.env.REACT_APP_API_URL;


//  "https://api-botbc.metatech.foundation";
export const USDT = "0x55d398326f99059fF775485246999027B3197955";
export const SC_PACKAGE = "0x4b0f9041Afb6d7432496181f0C2AEcBfcD5d3d52";
export const STANDARFEE = "0x9FF45A23E9a919D0799db364376Ce749b8062BC7";
export const FEEDEPLOY = "0";

export const WALLET_LIST = [
	{ icon: Metamask, title: 'Metamask', connectorId: 'injected' },
	{ icon: BinanceWallet, title: 'Binance', connectorId: 'bsc' },
	{ icon: TrustWallet, title: 'Trust Wallet', connectorId: 'trustWallet' },
	// { icon: BitWallet, title: 'Bitget Wallet', connectorId: 'bit' },
	// { icon: OKXWallet, title: 'OKX Wallet', connectorId: 'okx' },
	{ icon: SafePalWallet, title: 'SalePal Wallet', connectorId: 'safe' },
];

export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;

export const MAPPING_CHAINID: any = {
  bep: "0x38",
  erc: "0x1",
  poly: "0x89",
  arb: "0xa4b1",
  beptest: "0x61",
};
export const CHAINID_CONVERT: any = {
  56: "bep",
  1: "erc",
  137: "poly",
  66: "okc",
  42161: "arb",
  97: "beptest",
};

export const NATIVETOKENBYCHAIN: any = {
  56: {
    symbol: "BNB",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  1: {
    symbol: "ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  137: {
    symbol: "MATIC",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  66: {
    symbol: "OKT",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  10: {
    symbol: "OP_ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  43114: {
    symbol: "AVAX",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  250: {
    symbol: "FTM",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  42161: {
    symbol: "ARB_ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  8453: {
    symbol: "BASE_ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  59144: {
    symbol: "LINEA_ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  324: {
    symbol: "ERA_ETH",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
  1030: {
    symbol: "CFX",
    decimals: 18,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  },
};

export const Okx_Chainlist_His = [
  {
    chainId: "0",
    chainName: "All Network",
    chainImg: "https://static.okx.com/cdn/assets/imgs/226/DCA5D49F62393051.png",
  },
  {
    chainId: "56",
    chainName: "BNB Chain",
    chainImg: "https://static.okx.com/cdn/assets/imgs/223/E1E697A007152E88.png",
  },
  {
    chainId: "1",
    chainName: "Ethereum",
    chainImg: "https://static.okx.com/cdn/wallet/logo/ETH-20220328.png",
  },
  {
    chainId: "10",
    chainName: "Optimism",
    chainImg: "https://static.okx.com/cdn/wallet/logo/op_10000.png",
  },
  {
    chainId: "137",
    chainName: "Polygon",
    chainImg: "https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png",
  },
  {
    chainId: "66",
    chainName: "OKTC",
    chainImg: "https://static.okx.com/cdn/assets/imgs/223/CB94D13DB5CA7E2C.png",
  },
  {
    chainId: "43114",
    chainName: "Avalanche C",
    chainImg: "https://static.okx.com/cdn/wallet/logo/AVAX.png",
  },
  {
    chainId: "250",
    chainName: "Fantom",
    chainImg: "https://static.okx.com/cdn/wallet/logo/FTM-20220328.png",
  },
  {
    chainId: "42161",
    chainName: "Arbitrum",
    chainImg: "https://static.okx.com/cdn/wallet/logo/arb_9000.png",
  },
  {
    chainId: "8453",
    chainName: "Base",
    chainImg: "https://static.okx.com/cdn/wallet/logo/base_20900.png",
  },
  {
    chainId: "59144",
    chainName: "Linea",
    chainImg: "https://static.okx.com/cdn/wallet/logo/linea_eth_20700.png",
  },
  {
    chainId: "324",
    chainName: "zkSync Era",
    chainImg: "https://static.okx.com/cdn/wallet/logo/zk_17000.png",
  },
  // {
  //   chainId: "1030",
  //   chainName: "Conflux eSpace",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/cfx_19200.png",
  // },
];
export const CHAINIDTOSYMBOL_SEARCH: any = {
  56: "bsc",
  1: "eth",
  137: "polygon_pos",
  250: "ftm",
  66: "okc",
  43114: "avax",
  10: "op",
  42161: "arbitrum",
};
export const Okx_Chainlist = [

  {
    chainId: "56",
    chainName: "BNB Chain",
    chainImg: "https://static.okx.com/cdn/assets/imgs/223/E1E697A007152E88.png",
  },
  {
    chainId: "1",
    chainName: "Ethereum",
    chainImg: "https://static.okx.com/cdn/wallet/logo/ETH-20220328.png",
  },
  {
    chainId: "10",
    chainName: "Optimism",
    chainImg: "https://static.okx.com/cdn/wallet/logo/op_10000.png",
  },
  {
    chainId: "137",
    chainName: "Polygon",
    chainImg: "https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png",
  },
  {
    chainId: "66",
    chainName: "OKTC",
    chainImg: "https://static.okx.com/cdn/assets/imgs/223/CB94D13DB5CA7E2C.png",
  },
  {
    chainId: "43114",
    chainName: "Avalanche C",
    chainImg: "https://static.okx.com/cdn/wallet/logo/AVAX.png",
  },
  {
    chainId: "250",
    chainName: "Fantom",
    chainImg: "https://static.okx.com/cdn/wallet/logo/FTM-20220328.png",
  },
  {
    chainId: "42161",
    chainName: "Arbitrum",
    chainImg: "https://static.okx.com/cdn/wallet/logo/arb_9000.png",
  },
  {
    chainId: "8453",
    chainName: "Base",
    chainImg: "https://static.okx.com/cdn/wallet/logo/base_20900.png",
  },
  {
    chainId: "59144",
    chainName: "Linea",
    chainImg: "https://static.okx.com/cdn/wallet/logo/linea_eth_20700.png",
  },
  {
    chainId: "324",
    chainName: "zkSync Era",
    chainImg: "https://static.okx.com/cdn/wallet/logo/zk_17000.png",
  },

];

export const Okx_Chainlist_OrderLimit = [

  // {
  //   chainId: "56",
  //   chainName: "BNB Chain",
  //   chainImg: "https://static.okx.com/cdn/assets/imgs/223/E1E697A007152E88.png",
  // },
  {
    chainId: "1",
    chainName: "Ethereum",
    chainImg: "https://static.okx.com/cdn/wallet/logo/ETH-20220328.png",
  },
  // {
  //   chainId: "10",
  //   chainName: "Optimism",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/op_10000.png",
  // },
  // {
  //   chainId: "137",
  //   chainName: "Polygon",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png",
  // },
  // {
  //   chainId: "66",
  //   chainName: "OKTC",
  //   chainImg: "https://static.okx.com/cdn/assets/imgs/223/CB94D13DB5CA7E2C.png",
  // },
  // {
  //   chainId: "43114",
  //   chainName: "Avalanche C",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/AVAX.png",
  // },
  // {
  //   chainId: "250",
  //   chainName: "Fantom",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/FTM-20220328.png",
  // },
  // {
  //   chainId: "42161",
  //   chainName: "Arbitrum",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/arb_9000.png",
  // },
  // {
  //   chainId: "8453",
  //   chainName: "Base",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/base_20900.png",
  // },
  // {
  //   chainId: "59144",
  //   chainName: "Linea",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/linea_eth_20700.png",
  // },
  // {
  //   chainId: "324",
  //   chainName: "zkSync Era",
  //   chainImg: "https://static.okx.com/cdn/wallet/logo/zk_17000.png",
  // },

];


export const ADDRESS_FEE_SWAP = "0xFA2D46473dd0A75D8A90Fd86eD2de80a754E7F89";
export const FEE_PERCENT_SWAP = 0.09;

export const CHAINIDTOSYMBOL_FEE: any = {
  56: "BNB",
  1: "ETH",
  137: "MATIC",
  250: "FTM",
  66: "OKT",
  43114: "AVAX",
  10: "OP_ETH",
  42161: "ARB_ETH",
  8453: "BASE_ETH",
  59144: "LINEA_ETH",
  324: "ERA_ETH",
  1030: "CFX",
};
export const SYMBOLTOCHAINID: any = {
  bsc: 56,
  eth: 1,
  polygon: 137,
  polygon_pos: 137,
  ftm: 250,
  okc: 66,
  avax: 43114,
  op: 10,
  arb: 42161,
  arbitrum: 42161,
};
export const CHAINIDTOSYMBOL: any = {
  56: "bsc",
  1: "eth",
  137: "polygon",
  250: "ftm",
  66: "okc",
  43114: "avax",
  10: "op",
  42161: "arbitrum",
  8453: "base",
  59144: "linea",
  324: "zksync",
  1030: "conflux",
};

export const FROMTOKENBYCHAIN: any = {
  56: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/BNB-20220308.png",
    tokenName: "Binance Coin",
    tokenSymbol: "BNB",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 56,
  },
  1: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/ETH-20220328.png",
    tokenName: "Ethereum",
    tokenSymbol: "ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1,
  },
  137: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png",
    tokenName: "Polygon",
    tokenSymbol: "MATIC",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 137,
  },
  66: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/okt.png",
    tokenName: "OKTC",
    tokenSymbol: "OKT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 66,
  },
  10: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/op_10000.png",
    tokenName: "Optimism",
    tokenSymbol: "OP_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 10,
  },
  43114: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/AVAX.png",
    tokenName: "Avalanche",
    tokenSymbol: "AVAX",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 43114,
  },
  250: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/FTM-20220328.png",
    tokenName: "Fantom",
    tokenSymbol: "FTM",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 250,
  },
  42161: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/arb_9000.png",
    tokenName: "Arbitrum One",
    tokenSymbol: "ARB_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 42161,
  },
  8453: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/base_20900.png",
    tokenName: "Base",
    tokenSymbol: "BASE_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 8453,
  },
  59144: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/linea_eth_20700.png",
    tokenName: "Linea",
    tokenSymbol: "LINEA_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 59144,
  },
  324: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/zk_17000.png",
    tokenName: "zkSync Era",
    tokenSymbol: "ERA_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 324,
  },
  1030: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/cfx_19200.png",
    tokenName: "Conflux",
    tokenSymbol: "CFX",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1030,
  },
};
export const FROMTOKENBYCHAIN_ORDERLIMIT: any = {
  56: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/BNB-20220308.png",
    tokenName: "Binance Coin",
    tokenSymbol: "BNB",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 56,
  },
  1: {
    decimals: "18",
    tokenContractAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    tokenLogoUrl: "https://www.okx.com/cdn/wallet/logo/WETH-0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
    tokenName: "Ethereum",
    tokenSymbol: "WETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1,
  },
  137: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png",
    tokenName: "Polygon",
    tokenSymbol: "MATIC",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 137,
  },
  66: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/okt.png",
    tokenName: "OKTC",
    tokenSymbol: "OKT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 66,
  },
  10: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/op_10000.png",
    tokenName: "Optimism",
    tokenSymbol: "OP_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 10,
  },
  43114: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/AVAX.png",
    tokenName: "Avalanche",
    tokenSymbol: "AVAX",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 43114,
  },
  250: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/FTM-20220328.png",
    tokenName: "Fantom",
    tokenSymbol: "FTM",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 250,
  },
  42161: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/arb_9000.png",
    tokenName: "Arbitrum One",
    tokenSymbol: "ARB_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 42161,
  },
  8453: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/base_20900.png",
    tokenName: "Base",
    tokenSymbol: "BASE_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 8453,
  },
  59144: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/linea_eth_20700.png",
    tokenName: "Linea",
    tokenSymbol: "LINEA_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 59144,
  },
  324: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/zk_17000.png",
    tokenName: "zkSync Era",
    tokenSymbol: "ERA_ETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 324,
  },
  1030: {
    decimals: "18",
    tokenContractAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/cfx_19200.png",
    tokenName: "Conflux",
    tokenSymbol: "CFX",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1030,
  },
};

export const TOTOKENBYCHAIN: any = {
  56: {
    decimals: "18",
    tokenContractAddress: "0x55d398326f99059ff775485246999027b3197955",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 56,
  },
  1: {
    decimals: "6",
    tokenContractAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1,
  },
  137: {
    decimals: "6",
    tokenContractAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 137,
  },
  66: {
    decimals: "18",
    tokenContractAddress: "0x382bb369d343125bfb2117af9c149795c6c65c50",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 66,
  },
  10: {
    decimals: "6",
    tokenContractAddress: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether USD",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 10,
  },
  43114: {
    decimals: "6",
    tokenContractAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 43114,
  },
  250: {
    decimals: "6",
    tokenContractAddress: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
    tokenLogoUrl: "https://static.okx.com/cdn/wallet/logo/USDC.png",
    tokenName: "USD Coin",
    tokenSymbol: "USDC",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 250,
  },
  42161: {
    decimals: "6",
    tokenContractAddress: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 42161,
  },
  8453: {
    decimals: "18",
    tokenContractAddress: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/DAI-1691600167436.png",
    tokenName: "Dai Stablecoin",
    tokenSymbol: "DAI",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 8453,
  },
  59144: {
    decimals: "18",
    tokenContractAddress: "0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/WETH-1690217372089.png",
    tokenName: "Wrapped Ether",
    tokenSymbol: "WETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 59144,
  },
  324: {
    decimals: "18",
    tokenContractAddress: "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/WETH-0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
    tokenName: "Wrapped Ether",
    tokenSymbol: "WETH",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 324,
  },
  1030: {
    decimals: "18",
    tokenContractAddress: "0xfe97e85d13abd9c1c33384e796f10b73905637ce",
    tokenLogoUrl:
      "https://static.okx.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png",
    tokenName: "Tether USD",
    tokenSymbol: "USDT",
    holdingAmount: 0,
    priceUsd: 0,
    chainId: 1030,
  },
};


export const URL_TOKEN_BY_CHAIN: any = {
  56: {
    url: "https://tokens.pancakeswap.finance/coingecko.json",
  },
  1: {
    url: "",
  },
  137: {
    url: "",
  },
  66: {
    url: "",
  },
  10: {
    url: "",
  },
  43114: {
    url: "",
  },
  250: {
    url: "",
  },
  42161: {
    url: "",
  },
  8453: {
    url: "",
  },
  59144: {
    url: "",
  },
  324: {
    url: "",
  },
  1030: {
    url: "",
  },
  666888: {
    url: "",
  },
};

export const CHAINNAMEBYID: any = {
  56: 'BSC',
  97: 'BSC Testnet',
  1: 'Ethereum',
  137: 'Polygon',
  43114: 'Avalanche',
  250: 'Phantom',
  42161: 'Arbitrum',
  421613: "ArbitrumTest",
  666888: "HeLaTestnet",
  8668: "HeLa Mainnet"
};

export const CHAIN_RPC: any = {
  56: 'https://bsc-dataseed1.binance.org',
  137: 'https://polygon-rpc.com',
  8668: 'https://mainnet-rpc.helachain.com	'
};


export const Chainlist = [
  {
    chainId: "56",
    chainName: "BNB Chain",
    chainCurency: "BNB",
    chainImg: "../../../images/bot/bnb.png",
  },
  {
    chainId: "97",
    chainName: "BNB Chain Testnet",
    chainCurency: "BNB",
    chainImg: "../../../images/bot/bnb.png",
  },
  {
    chainId: "1",
    chainName: "Ethereum",
    chainCurency: "ETH",
    chainImg: "../../../images/bot/eth.png",
  },
  {
    chainId: "137",
    chainName: "Polygon",
    chainCurency: "MATIC",
    chainImg: "https://icons.llamao.fi/icons/chains/rsz_polygon.jpg",
  },
];
export const price_range = (fee: any) => {
  switch (fee) {
    case 100:
      return [0.1, 0.5, 1, 100];
    case 500:
      return [5, 10, 20, 100];
    case 2500:
      return [10, 20, 50, 100];
    case 10000:
      return [10, 20, 50, 100];
    default:
      return [5, 10, 20, 100];
  }
};
export const token_network = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "ETH";
    case 137:
      return "MATIC";
    case 42161:
      return "ETH";
    case 8668:
      return "HLUSD";
    case 666888:
      return "HLUSD";
    default:
      return "BNB";
  }
};
export const type_bot = (type: any) => {
  switch (type) {
    case "1":
      return "Trade volume";
    case "2":
      return "Random buy sell";
    default:
      return "Trade volume";
  }
};
export const type_status = (stt: any) => {
  switch (stt) {
    case true:
      return "Running";
    case false:
      return "Waiting";
    default:
      return "Waiting";
  }
};
export const type_status_bot = (stt: any) => {
  switch (stt) {
    case true:
      return "Started";
    case false:
      return "Not Started";
    default:
      return "Runing";
  }
};
export const status_difficulty = (number: any) => {
  const productValue = Math.pow(16, number);

  return productValue;
};

export const USDT_DECIMAL: any = {
  97: 18,
  56: 18,
  42161: 6,
  1: 6,
};
export const SC_STAKING_CHAIN: any = {
  97: "0xED08076C42e29B3e2b0d4C16586cbb73FCD1D055",
  56: "",
  42161: "",
  1: "",
};
export const SC_CLAIM_CHAIN: any = {
  97: "0xac8228Cf9788317a5d34e42F045eF4416b3be48E",
  56: "",
  42161: "",
  1: "",
};
export const USDT_STAKE_CHAIN: any = {
  97: "0xB7611e995fefD3aA282A5B315a69011473530EB8",
  56: "",
  42161: "",
  1: "",
};

export const LOGO_CHAIN: any = {
  56: "../../../images/bot/bnb.png",
  1: "../../../images/bot/eth.png",
  42161: "../../../images/bot/arb.png",
  137: "../../../images/bot/poly.png",
  250: "../../../images/bot/fantom.png",
};

export const BSC_ROUTER_PANCAKE_V2 = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const WBNB_BSC = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const BUSD_BSC = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const USDT_BSC = "0x55d398326f99059fF775485246999027B3197955";
export const USDC_BSC = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";

export const ETH_PANCAKE_FACTORY_ADDRESS_V2 =
  "0x1097053Fd2ea711dad45caCcc45EfF7548fCB362";
export const ETH_ROUTER_PANCAKE_V2 = "0xEfF92A263d31888d860bD50809A8D171709b7b1c";
export const WETH_ETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const USDT_ETH = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const USDC_ETH = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";


export const ARB_PANCAKE_FACTORY_ADDRESS_V2 =
  "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E";
export const ARB_ROUTER_PANCAKE_V2 = "0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb";
export const WETH_ARB = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
export const USDC_ARB = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
export const USDT_ARB = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";

export const WSS_PROVIDER_BSC =
  "wss://bsc.getblock.io/c3a05478-f154-4597-b457-8b313db1080e/mainnet/";
export const WSS_PROVIDER_ETH =
  "wss://eth.getblock.io/c3a05478-f154-4597-b457-8b313db1080e/mainnet/";
export const WSS_PROVIDER_ARB =
  "https://arb.getblock.io/c3a05478-f154-4597-b457-8b313db1080e/mainnet/";

export const BSC_ETH_ARB_PANCAKE_FACTORY_ADDRESS_V3 =
  "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865";