import React from "react";
import Countdown from "react-countdown";

const CountdownReloadComponent = (countdownDate) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed){
      window.location.reload();
    };
    return (
      <div className="coming-time">
        <div className="time-count flex-left">
          {/* <img src="../images/clock.svg" />  */}
          <span>
            {/* End in:  */}
            <span className="text-green">
              <span className="fsize-16">{days}</span>d <span className="fsize-16">{hours}</span>h <span className="fsize-16">{minutes}</span>m <span className="fsize-16">{seconds}</span>s</span>
            </span>
        </div>
      </div>
    );
  };

  return <Countdown date={countdownDate} renderer={renderer} />;
};

export default CountdownReloadComponent;
