
import { Col, message, Tabs, Layout, Row, Empty } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";


import { AbiItem } from "web3-utils";
import abiStake from '../../config/abi/abiStake.json'
import type { TabsProps } from 'antd';
import HeaderApp from "../header-app";
import Staking from "./staking";
import { BaseApi } from "../../config/api/BaseApi";
import Statistics from "./statistics";
import Footer from "../../component/common/Footer";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import { useParams } from "react-router-dom";


declare const window: Window &
  typeof globalThis & {
      trustwallet: any;
      ethereum: any;
      bitkeep: any;
      okexchain: any;
      safepalProvider: any;
  };

const StakingPage = () => {

  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId: any = selectedNetworkId;

  const { seo } = useParams();
  
  // const [amountTotalStaked, setAmountTotalStaked]: any = useState(0);
  const [countStaked, setCountStaked]: any = useState(0);
  const [pool, setPool]: any = useState(null);
  const [campaign, setCampaign]: any = useState(null);
  const [statistics, setStatistics]: any = useState();
  const [configApy, setConfigApy]: any = useState();

  let web3:any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

  if (connectorId === "io.metamask" || connectorId === "bsc") {
      web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
      web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
      web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
      web3 = new Web3(window.okexchain);
  } else {
      web3 = new Web3(window.ethereum);
  }

  const [param, setParam]: any = useState({
    page: 1,
    size: 100,
    chainId: ''
  });

  const getPool = async (seo:any) => {
    await BaseApi.getPoolDetail(seo).then((res)=>{
      
      if(res.status == 200 && res.data && res.data.data){
          setCampaign(res.data.data);
          let poolsData:any = res.data.data?.pools;
          
          let poolChain = poolsData.filter((item:any)=> Number(item.chain_id) == Number(chainId));
          if(poolChain.length > 0){
            setPool(poolChain[0]);
            localStorage.setItem('poolChainId', poolChain[0]?.chain_id);
          }else{
            setPool(poolsData[0]);
            localStorage.setItem('poolChainId', poolsData[0]?.chain_id);
          }
        }
    }).catch((error)=>{
      console.log(error);
    });
  }

  useEffect(()=>{
    if(seo){
      getPool(seo);
    }
    
      
  }, [seo, chainId]);


  let stakeAddress = pool?.address;

  let stakeContract:any;
  if(stakeAddress){
    stakeContract = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        stakeAddress
    );
  }

  const getStatistics = async () => {
      await BaseApi.getStatistics().then((res)=>{
        if(res.status == 200 && res.data.data){
          setStatistics(res.data.data);
        }
      }).catch((error)=>{
      console.log(error);
    });
  }
  const getConfigApy = async () => {
    await BaseApi.getConfigApy().then((res)=>{
      if(res.status == 200 && res.data.data){
        setConfigApy(res.data.data);
      }
    }).catch((error)=>{
      console.log(error);
    });
  }
  useEffect(() => {
      getConfigApy();
      getStatistics();

  }, [])


  let hisTab = localStorage.getItem('keyTab');
  const [activeTab, setActiveTab] = useState<any>(hisTab ? hisTab : 'staking');

  const items: TabsProps['items'] = [
    
    // {
    //   key: 'staking',
    //   label: <div className="flex-left"><img src="../images/staking.svg"></img> <span>Stake</span></div>,
    //   children: <><Staking campaign={campaign} configApy={configApy} pool={pool}/></>,
    // },
    // {
    //   key: 'claim',
    //   label: <div className="flex-left"><img src="../images/wallet-money.svg"></img> <span>Unstake</span></div>,
    //   children: <><Withdraw campaign={campaign} pool={pool} configApy={configApy}/></>,
    // }
  ];

  const onChangeTabs = (key: string) => {
    localStorage.setItem('keyTab', key);
    setActiveTab(key);

    if(seo){
      getPool(seo);
    }
    getConfigApy();
    getStatistics();

    // window.location.reload();
  };
  
  return (
    <>
      <div className="app-page bg-main">
        <div className="page-content">
          <HeaderApp />
          <div className="container">
            <h2 className="text-center mb-15 head-title">Pool Staking</h2>
          
            
            <div className="statistic-container">
              <Statistics campaign={campaign} configApy={configApy} pool={pool} statistics={statistics}/>
            </div>

            {pool ? (
              <>
                <Staking style={{ marginTop: 30 }} campaign={campaign} configApy={configApy} pool={pool}/>
              </>
            ):(
              <>
                <Empty  description={<span className="text-white">Pool not found.</span>}/>
              </>
            )}

            
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default StakingPage;
