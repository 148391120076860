export const formatNumber = (x, max) => {
  let tem = x * 10000000
  let tem1 = Math.floor(tem)
  let tem2 = tem1 / 10000000
  if (tem2) {
    return x.toLocaleString("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: max,
    });
  } else return 0;
};
export const formatNum = (number) => {
  if (Number(number) === 0) {
    return 0;
  }
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-US").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return `${Number(number).toFixed(7)}`;
    }
  }
};

export const decimalPrice = (number) => {
  if (number && Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return i - 1;
      }
    }
  } else {
    return 5;
  }
};

export const formatNumNotDolla = (number) => {
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">$0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-IN").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return Number(number).toFixed(7);
    }
  }
};

function roundDownSignificantDigits(number, decimals) {
  let significantDigits = (parseInt(number.toExponential().split('e-')[1])) || 0;
  let decimalsUpdated = (decimals || 0) +  significantDigits - 1;
  decimals = Math.min(decimalsUpdated, number.toString().length);

  return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}

export const numberDownDecimal = (bal, deci) => {

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 5,
    maximumSignificantDigits: 5
  })

  let number = formatter.format(roundDownSignificantDigits(bal,deci));
  return Number(number);
};
