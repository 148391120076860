import { instance } from '../../apiBase/instance';
var authToken:any = localStorage.getItem('authToken');

export const BaseApi = {

	getPoolList(param:any) {
		return instance.get<any>(`api/campaigns`, {
			params: param
		});
	},
	getPoolDetail(seo:any) {
		return instance.get<any>(`api/campaigns/${seo}`, {});
	},
	getUserStakeInfos(user:any, campaignId:any) {
		return instance.get<any>(`/api/user-stake-infos/user/${user}/campaign/${campaignId}`, {});
	},
	getUserDailyRewards(address:any) {
		return instance.get<any>(`api/user-snapshots/daily-rewards/${address}`, {});
	},
	getUserClaimInfo(address:any) {
		return instance.get<any>(`api/user-info/${address}`, {});
	},
	getRefCode(address:any) {
		return instance.get<any>(`api/referrals/user/${address}`, {});
	},
	
	getStatistics() {
		return instance.get<any>(`api/pools/statistics`, {});
	},
	getExpectedReturns() {
		return instance.get<any>(`api/snapshots/expected-returns`, {});
	},
	getStakingPlatformsCharts() {
		return instance.get<any>(`api/staking-platforms/charts?startTime=2024-03-20T08%3A00%3A00Z&endTime=2024-12-20T08%3A00%3A00Z`, {});
	},
	getStakingStablehodlCharts() {
		return instance.get<any>(`api/staking-platforms/stablehodl-charts?startTime=2024-03-20T08%3A00%3A00Z&endTime=2024-12-20T08%3A00%3A00Z`, {});
	},
	getTradePlatformData() {
		return instance.get<any>(`api/trading-platforms/partners`, {});
	},
	getConfigApy() {
		return instance.get<any>(`api/configs/apy`, {});
	},
	getLeaderboards(param:any) {
		return instance.get<any>(`api/leaderboards`, {
			params: param
		});
	},
	getRecentJoin(param:any) {
		return instance.get<any>(`api/leaderboards/recent-joins`, {
			params: param
		});
	},
	getHistories(param:any, wallet:any) {
		return instance.get<any>(`api/claim-yields/user/${wallet}?wallet=${wallet}`, {
			params: param
		});
	},
	updateEst(data:any) {
	
		return instance.post<any>(`api/est/submit` ,data, {});
	},
	setRefCode(data:any) {
		return instance.post<any>(`api/referrals` ,data, {});
	},
	requestClaimYield(data:any) {
	
		return instance.post<any>(`api/claim-yields/request-claim-yield` ,data, {});
	},
	updateClaimYieldHash(data:any) {
	
		return instance.put<any>(`api/claim-yields/update-claim-yield-hash` ,data, {});
	},
	requestUnstake(data:any) {
	
		return instance.post<any>(`api/un-stakes/request` ,data, {});
	},
	checkUserRequested(address:any) {
		return instance.get<any>(`api/un-stakes/check/user/${address}`, {});
	},

	signUnstake(data:any) {
	
		return instance.post<any>(`api/un-stakes/get-sign` ,data, {});
	},
	updateUnstakeHash(data:any) {
	
		return instance.put<any>(`api/un-stakes/update` ,data, {});
	},
	getUnstakeHistories(param:any, wallet:any) {
		return instance.get<any>(`api/un-stakes/user/${wallet}?wallet=${wallet}`, {
			params: param
		});
	},
};
