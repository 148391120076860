/* eslint-disable no-restricted-globals */
import { Col, Drawer, Menu } from "antd";
import type { MenuProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import SwithChain from "../component/ConnectWallet/SwitchChain";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../component/ConnectWallet/wagmi-connect";
import queryString from 'query-string';
import { BaseApi } from "../config/api/BaseApi";
import { notiError, notiSuccess } from "../utils/notication";

declare const window: Window &
  typeof globalThis & {
      trustwallet: any;
      ethereum: any;
      bitkeep: any;
      okexchain: any;
      safepalProvider: any;
  };

const HeaderApp = () => {

  const { address, connector, isConnected } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  
  let refCode:any = '';
  let paramUrl:any = queryString.parse(location.search);
  if (paramUrl.pts !== undefined && paramUrl.pts.length > 0) {
      refCode = paramUrl.pts;
  }



  let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : null;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let web3:any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

  if (connectorId === "io.metamask" || connectorId === "bsc") {
      web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
      web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
      web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
      web3 = new Web3(window.okexchain);
  } else {
      web3 = new Web3(window.ethereum);
  }

  let defaut:any = 'stake';
  let path = window.location.pathname;

  if(path == "/claim"){
    defaut = 'claim';
  }
  if(path == "/leaderboard"){
    defaut = 'leaderboard';
  }
  if(path == "/trade"){
    defaut = 'trade';
  }
  if(path == "/"){
    defaut = 'home';
  }

  const [current, setCurrent] = useState(defaut);
  const items: MenuProps['items'] = [
    {
      label: (
        <a href="/">Home</a>
      ),
      key: 'home',
    },
    {
      label: (
        <a href="/stakes">Stake</a>
      ),
      key: 'stake',
    },
    {
      label: (
        <a href="/claim">Claim</a>
      ),
      key: 'claim',
    },
    {
      label: (
        <a href="/leaderboard">Leaderboard</a>
      ),
      key: 'leaderboard',
    },
    {
      label: (
        <a href="/profile">Profile</a>
      ),
      key: 'profile',
    },
    {
      label: (
        <a href="/trade">Trade HLUSD</a>
      ),
      key: 'trade',
    },
    {
      label: (
        <a href="https://docs.stablehodl.com" target="_blank">Docs</a>
      ),
      key: 'docs',
    },
  ];

  const onClickActiveMenu: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
      setOpen(false);
  };

  const message = "Welcome to StableHodl!";
  const getSignMeta = async ()=>{
    if(signatureMeta == null){
      const signature = await web3.eth.personal.sign(message, address);
      localStorage.setItem('signature', signature);
      window.location.reload();
      if(refCode){
        setupRefcode(address, signature);
      }
      
    }
  }

  const setupRefcode = async (address:any, signature:any) => {
    if(!address && refCode){
      notiError('Connect your wallet to activate Invitation Code.', 3);
      return;
    }
  
    let payload:any = {
      "wallet": address,
      "message": message,
      "signature": signature,
      "code": refCode
    }
    await BaseApi.setRefCode(payload).then((res)=>{
        if(res.status == 201 && res.data && res.data.data){
          notiSuccess('Invitation Code is valid!', 3);
          console.log('set ref success');
          window.location.reload();
        }else{
            // setRefCode(null)
        }
    }).catch((err)=>{
        console.log('set ref error', err);
    });
  }
  
  useEffect(() => {
    if(address && signatureMeta == null){
      getSignMeta();
    }

  }, [address, signatureMeta]);

  useEffect(() => {
    if(address && window?.ethereum){
      window.ethereum.on('accountsChanged', function () {
        localStorage.removeItem('signature');
      });
    }
  }, [address]);

  useEffect(() => {
    if(refCode != ''){
      setupRefcode(address, signatureMeta);
    }

  }, [address, signatureMeta, refCode]);
  
  return (
    <>
    {isMobile ? (
        <>
          <header className="header">
            <div className="main-header">
              <div className="header-left">
                <div className="icon-bar" onClick={showDrawer}>
                  <Link to="/"><img src="../../../images/logo-short.svg" alt="" /></Link>
                </div>
              </div>
              <div className="select-fiter">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
              <div className="round-connect">
                <div className="icon-bar" onClick={showDrawer}>
                  <img width="40" src="../../../images/bar-m-1.png" alt="" />
                </div>
              </div>
            </div>
          </header>
          <Drawer className="drawer-menu" title="" placement="right" onClose={onClose} open={open}>
              <div className="menu-group">
                  <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="vertical" items={items} />
              </div>
          </Drawer>
        </>
      ) : (
        <header className="header">
          <div className="main-header">
            <div className="header-left">
              <div className="box-img">
                <Link to="/"><img src="../../../images/stable-hodl.svg" alt="" /></Link>
              </div>
            </div>
            <div className="header-center">
              <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="horizontal" items={items} />
              
            </div>
            <div className="header-right">
              {address && (
                <>
                <SwithChain />
                </>
              )}
              
              <div className="round-connect">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
            </div>
          </div>
        </header>
      )}
      
      
    </>
  );
};
export default HeaderApp;
