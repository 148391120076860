
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row } from "antd";



declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };

    

const UserInfo = (prop:any) => {

    const { claimInfo, stakedInfo } = prop;

    return (
        <>
             <Row gutter={30}>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info mb-15" style={{minHeight: '130px'}}>
                        <Row gutter={10 }>
                            <Col xs={6} sm={4}>
                                <div className="sum-info-img">
                                    <img src="../images/info1.svg" />
                                </div>
                            </Col>
                            <Col xs={18} sm={20}>
                                <p className="mt-0 text-white weight-600">Stake Balance</p>
                                <p className="text-gray-light flex-btw">
                                    <span>USDT: </span>
                                    <span><span className="weight-500">{claimInfo && claimInfo?.usdt_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdt_in_hlusd_amount) : 0}</span> HLUSD</span>
                                </p>
                                <p className="text-gray-light flex-btw">
                                    <span>USDC: </span>
                                    <span><span className="weight-500">{claimInfo && claimInfo?.usdc_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.usdc_in_hlusd_amount) : 0}</span> HLUSD </span>
                                </p>
                                <p className="text-gray-light flex-btw mb-0">
                                    <span>HLUSD: </span>
                                    <span><span className="weight-500">{claimInfo && claimInfo?.native_in_hlusd_amount > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.native_in_hlusd_amount) : 0}</span> HLUSD </span>
                                </p>
                            </Col>
                        </Row>
                        
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info mb-15" style={{minHeight: '130px'}}>
                        <p className="text-white weight-600 mt-0">Yield Earned</p>
                        <p className="mb-0"><span className="text-white weight-600">{claimInfo ? new Intl.NumberFormat("ja-JP").format(claimInfo?.reward) : 0}</span> <span className="text-gray-light">HLUSD</span></p>
                    </div>
                </Col>
            </Row>
            
            
            
        </>
    );
};
export default UserInfo;
