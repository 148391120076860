
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Select, Space, Button, Modal, Spin, Table, Tag, Empty } from "antd";
import type { TabsProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import Web3 from "web3";
import {ExclamationCircleFilled, BankOutlined, LoadingOutlined} from '@ant-design/icons'
import OTCTradeAbi from '../../config/abi/OTCTradeAbi.json';
import { AbiItem } from "web3-utils";
import type { CollapseProps } from 'antd';
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import networkChange from "../../utils/networkChange";
import { TradeApi } from "../../config/api/TradeApi";
import { notiError, notiSuccess } from "../../utils/notication";
import { convertFromWei } from "../../utils/convertNumber";
import { CHAINNAMEBYID } from "../../constants";

declare const window: Window &
    typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft:'5px' }} spin />;

const ClaimTrade = () => {

    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId;
    let signatureMeta:any = localStorage.getItem('signature');
    let web3: any;

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const [loading, setLoading]: any = useState(false);
    const [loadingConfirm, setLoadingConfirm]: any = useState(false);
    const [loadingKey, setLoadingKey]: any = useState(0);
    const [modalBuyHistory, setModalBuyHistory]: any = useState(false);
    const [orders, setOrders]: any = useState([]);
    const [modalConfirmed, setModalConfirmed]: any = useState(false);
 

    const [param, setParam]: any = useState({
        page: 1,
        size: 20
    });
    

    const getOrdersByWallet = async (address:any) => {
        setLoading(true);
        await TradeApi.getOrdersByWallet(address, param).then((res:any)=>{
       
            if(res.status == 200 && res.data && res.data.data){
                let orderData:any = res.data.data;
                setOrders(orderData);
                setLoading(false);
            }
        }).catch((error)=>{
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(()=>{
        if(address){
            getOrdersByWallet(address);     
        }
    }, [address, param]);
    
    const handleChangeNetwork = (value: any) => {
        networkChange(value);
    };

    const handleShowBuyHistory = () => {
        setModalBuyHistory(true);
    };

    const handleClaimToken = async (record:any, index:any) => {
        try {
            setLoadingConfirm(true);
            setLoadingKey(index);

            if(record?.receipt?.dst_chain_id != chainId){
                networkChange(record?.receipt?.dst_chain_id);
            }

            
            if (!signatureMeta) {
                notiError('Wrong signature', 4)
                setLoadingConfirm(false)
                return
            }

            let sellContract:any = '';
            if(record?.receipt?.dst_contract){
                sellContract = new web3.eth.Contract(
                    OTCTradeAbi as unknown as AbiItem,
                    record?.receipt?.dst_contract
                );
            }

            
            if (sellContract == '') {
                notiError('Contract invalid', 4)
                setLoadingConfirm(false)
                return
            }
          
            // let arrData:any = [record?.receipt?.src_token, record?.receipt?.amount_in_wei, record?.receipt?.dst_token, record?.receipt?.dst_chain_id, record?.receipt?.amount_out_wei, record?.receipt?.price_wei, record?.receipt?.fee_wei, record?.receipt?.free_gas];
            
            
            const gasPrice = await web3.eth.getGasPrice();

            const gasEstimate = await sellContract.methods
                .tradeOut(
                    record?.receipt?.id,
                    record?.receipt?.dst_token,
                    record?.user_address,
                    record?.receipt?.amount_out_wei,
                    record?.receipt?.dst_chain_id,
                    record?.receipt?.round_id,
                    record?.receipt?.claim_signature
                )
                .estimateGas({
                    from: address,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await sellContract.methods.tradeOut(
                record?.receipt?.id,
                record?.receipt?.dst_token,
                record?.user_address,
                record?.receipt?.amount_out_wei,
                record?.receipt?.dst_chain_id,
                record?.receipt?.round_id,
                record?.receipt?.claim_signature
            )
            .send({
                from: address,
                gas: gasEstimate,
                gasPrice: web3.utils.toHex(String(gasPrice)),
            })
            .then(async (resClaim: any) => {
                
                const interval = setInterval(function () {
                    web3.eth.getTransactionReceipt(
                        resClaim?.transactionHash,
                        async function (err: any, rec: any) {
                            if (rec) {
                                
                                // notiSuccess('Claimed Successfully', 5);
                              
                                clearInterval(interval);

                                setTimeout(() => {
                                    
                                    setModalConfirmed(true);
                                    setLoadingConfirm(false);
                                    getOrdersByWallet(address); 

                                }, 2500);
                               
                            }
                            if (err) {
                                console.log("err", err);
                                setLoadingConfirm(false)
                                notiError('Claimed Failed', 3)
                            }
                        }
                    )
                }, 1000);
            })
            .catch((errStake: any) => {
                console.log('errStake', errStake)
                notiError(errStake?.message || errStake, 3)
                setLoadingConfirm(false)
            })

        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingConfirm(false)
        }
    };

    const columns: any = [
        {
            title: <div className="text-center">Chain</div>,
            dataIndex: '',
            render: (text:any, record:any, index:any) => {
                
                let chainName:any = CHAINNAMEBYID[record?.receipt?.dst_chain_id];
        
                return (
                    <><div className="text-center">{chainName}</div></>
                )
            }
        },
        {
            title: <div className="text-center">Amount</div>,
            dataIndex: 'amount',
            render: (text:any, record:any) => {
                
                return (
                    <div className="text-info text-center">
                        {record?.receipt?.amount_out ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.receipt?.amount_out) : 0} <small className="text-gray-light">{record?.receipt?.dst_token_symbol}</small>
                    </div>
                )
            }
        },
        {
            title: <div className="text-center">Status</div>,
            dataIndex: 'status',
            render: (text:any, record:any, index:any) => {

                let chainName = CHAINNAMEBYID[record?.receipt?.dst_chain_id];
                    
            return (
                <div className="text-info text-center">
                    {record?.trade_out_status == "PENDING" && record?.dst_transaction == "" ? (
                        <>
                            {record?.receipt?.dst_chain_id == chainId ? (
                                <>
                                    <Button size="large" disabled={!address || record?.user_address.toLowerCase() != address.toLowerCase() || (loadingConfirm && loadingKey == index)} onClick={()=>handleClaimToken(record, index)} className="btn-green" htmlType="button">Claim {record?.receipt?.dst_token_symbol} {loadingConfirm && loadingKey == index && <Spin indicator={antIcon} />}</Button>
                                </>
                            ):(
                                <>
                                    <Button size="large" disabled={!address} onClick={()=>handleChangeNetwork(record?.receipt?.dst_chain_id)} className="btn-danger" danger htmlType="button">Switch to {chainName}</Button>
                                </>
                            )}
                            
                        </>
                    ):(
                        <>
                            <Tag color="red">{record?.trade_out_status}</Tag>
                        </>
                    )}
                    
                </div>
            )}
        },
    ];

    const handleConfirmDone = ()=>{
        setModalConfirmed(false);
        window.location.reload();
    }
  
    return (
        <>
            <div className="table-cus mt-15">
                {loading ? (
                    <>
                        <div className="text-center"><Spin size="large"/></div>
                    </>
                ):(
                    <>
                        {orders.length > 0 ? (
                            <Table scroll={{y: 500 }} className="table-order" pagination={false} columns={columns} dataSource={orders}/>
                        ):(
                            <div className="p-15"><Empty description={<span className="text-white">No data</span>} /></div>
                        )}
                        
                    </>
                )}
                
            </div>
            <Modal footer={false} className="modal-cus p-50" title={<></>} open={modalConfirmed} onOk={()=> window.location.reload()} onCancel={()=> window.location.reload()}>
                <div className="modal-comfirm-buy">
                    <p className="text-white mt-0 text-center font-20 mb-0">Claimed Successfully</p>
                    <p className="text-gray-light mt-0 mb-0 text-center">Congratulations! Your transaction has been processed.</p>
                    <p className="text-gray-light mt-0 text-center"><img src="../images/cool.svg" /></p>
                    <div className="d-flex-center mt-15">
                        <Button size="large" onClick={handleConfirmDone} className="btn-green full-width" htmlType="button">Done</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default ClaimTrade;
