
import { Col, message, Tabs, Layout, Row, Select, Empty, Tooltip, Table, Pagination, Spin } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";

import { CaretDownOutlined, CopyOutlined } from "@ant-design/icons";
import type { TabsProps } from 'antd';
import HeaderApp from "../header-app";
import { CHAINNAMEBYID, SC_STAKING_CHAIN } from "../../constants";
import { BaseApi } from "../../config/api/BaseApi";
import CountdownComponent from "../../utils/countDown";
import Footer from "../../component/common/Footer";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import CopyToClipboard from "react-copy-to-clipboard";
import { notiSuccess } from "../../utils/notication";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import { Line } from "@ant-design/charts";

const { Option } = Select;
declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};

const Leaderboard = () => {

    const { address } = useAccount();
    const [totalRow, setTotalRow] = useState(0);
    const [leaderboards, setLeaderboards]: any = useState([]);
    const [recentJoin, setRecentJoin]: any = useState([]);
    const [refCode, setRefCode]: any = useState(null);
    const [statistics, setStatistics]: any = useState(null);
    const [configApy, setConfigApy]: any = useState(null);
    const [loading, setLoading]: any = useState(false);
    const [open, setOpen] = useState(false);
    

    let web3:any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const [paramLeaderboard, setParamLeaderboard]: any = useState({
        page: 1,
        size: 200
    });
    const [param, setParam]: any = useState({
        page: 1,
        size: 100
    });

    const getRefCode = async (address:any) => {
        await BaseApi.getRefCode(address).then((res)=>{
            if(res.status == 200 && res.data && res.data.data){
                setRefCode(res.data.data);
            }else{
                setRefCode(null)
            }
        }).catch((err)=>{
            console.log('ClaimInfo', err);
        });
    }

    const getLeaderboards = async () => {
        setLoading(true);
        await BaseApi.getLeaderboards(paramLeaderboard).then((res)=>{
            
            if(res.status == 200 && res.data && res.data.data){
                let datas:any = res.data.data;
                for (let i = 0; i < datas.length; i++) {
                    let no = i + 1
                    datas[i].no = no;
                }
                setTotalRow(res.data.total);
                setLeaderboards(datas);
                setLoading(false);
            }else{
                setLeaderboards([]);
                setLoading(false);
            }
        }).catch((err:any)=>{
            console.log('err', err);
            setLoading(false);
        });
    }

    const getRecentJoin = async () => {
        await BaseApi.getRecentJoin(param).then((res)=>{
            
            if(res.status == 200 && res.data && res.data.data){
                let datas:any = res.data.data;
                setRecentJoin(datas);
            }else{
                setRecentJoin([])
            }
        }).catch((err:any)=>{
        console.log('err', err);
        });
    }
    const getStatistics = async () => {
      await BaseApi.getStatistics().then((res)=>{
        if(res.status == 200 && res.data.data){
          setStatistics(res.data.data);
        }
      }).catch((error)=>{
        console.log(error)
      });
    }
    const getConfigApy = async () => {
        await BaseApi.getConfigApy().then((res)=>{
            if(res.status == 200 && res.data.data){
            setConfigApy(res.data.data);
            }
        }).catch((error)=>{
            console.log(error)
        });
    }

    
  
    useEffect(() => {
      getConfigApy();
      getStatistics();
      

    }, []);

    useEffect(() => {
        
        if (address) {
            getRefCode(address);
        }
    }, [address]);

    useEffect(() => {
        getLeaderboards();
    }, [paramLeaderboard]);

    useEffect(() => {
        getRecentJoin();
    }, [param])

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const columns: any = [
    {
        title: <div className="text-center text-green">Rank Deposited</div>,
        dataIndex: '',
        render: (text:any, record:any, index:any) => {
            let idex = index+1;
            let syml = <span>{idex}</span>;
            if(paramLeaderboard?.page >= 2){
                syml = <span>{idex + (paramLeaderboard?.page*paramLeaderboard?.size)}</span>;
            }
            if(paramLeaderboard?.page == 1){
                if(idex == 1){
                    syml = <><span className="text-green weight-600">1</span></>;
                }
                if(idex == 2){
                    syml = <><span className="text-green weight-600">2</span></>;
                }
                if(idex == 3){
                    syml = <><span className="text-green weight-600">3</span></>;
                }
            }
            return (
                <><div className="text-center">{syml}</div></>
            )
        }
    },
    {
        title: <div className="text-center">Name</div>,
        dataIndex: 'address',
        render: (text:any, record:any) => {
                
        return (
            <div className="text-info text-center">
                {record?.address ? `${record?.address.substring(0, 4)}...${record?.address.substring(record?.address.length - 4)}` : "---"}
            
            </div>
        )}
    },
    {
        title: <div className="text-center">Invited By</div>,
        dataIndex: 'invited_by',
        render: (text:any, record:any) => {
                
        return (
            <div className="text-info text-center">
                {record?.ref_link?.owner?.address ? `${record?.ref_link?.owner?.address.substring(0, 4)}...${record?.ref_link?.owner?.address.substring(record?.ref_link?.owner?.address.length - 4)}` : "---"}
            
            </div>
        )}
    },
    {
        title: <div className="text-center">Points</div>,
        dataIndex: 'points',
        render: (text:any, record:any) => {
            return (
                <div className="text-info text-center">
                    {record?.points ? new Intl.NumberFormat("ja-JP").format(record?.points) : 0}
                </div>
            )
        }
    },
    {
        title: <div className="text-center">Earned (HLUSD)</div>,
        dataIndex: 'reward',
        render: (text:any, record:any) => {
            return (
                <div className="text-info text-center">
                    {new Intl.NumberFormat("ja-JP").format(record?.lock_reward + record?.reward)}
                </div>
            )
        }      
    
    }];

    let baseUrl:any = process.env.REACT_APP_BASE_URL;

    const onCopy = ()=>{
        notiSuccess('Copied', 3)
    }

    let refStr = `${baseUrl}?pts=${refCode?.code}`;

    

    return (
        <>
            <div className="app-page bg-main">
                <div className="bg-bottom">
                    <HeaderApp />
                    <div className="container">
                        <div className="powered mt-30">
                            
                            <div className="powered-list mt-0">
                                <Row gutter={30}>
                                    <Col xs={24} sm={18}>
                                        <div className="leaderboard-static">
                                            <Row>
                                                <Col xs={24} sm={8}>
                                                    <div className="static-item">
                                                        <div className="text-left static-title">TVL</div>
                                                        <div className="text-right static-num">${ statistics ? new Intl.NumberFormat("ja-JP").format(statistics?.amount) : 0}</div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={8}>
                                                    <div className="static-item">
                                                        <div className="text-left static-title">APY</div>
                                                        <div className="text-right static-num">{ configApy?.avg_apy_str }</div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={8}>
                                                    <div className="static-item">
                                                        <div className="text-left static-title">Users</div>
                                                        <div className="text-right static-num">{statistics ? new Intl.NumberFormat("ja-JP").format(statistics?.total_user) : 0}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        <div className="table-leader">
                                            {loading ? (
                                                <>
                                                    <div className="text-center"><Spin size="large"/></div>
                                                </>
                                            ):(
                                                <>
                                                <Table scroll={{y: 500 }} className="table-leaderboard" pagination={false} columns={columns} dataSource={leaderboards}/>
                                                </>
                                            )}
                                            
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={6}>
                                        <div className="recent-join">
                                            <div className="recent-title">Recent Joins</div>
                                            <div className="recent-list">
                                                {recentJoin.length > 0 ? recentJoin.map((item:any, key:any) => {

                                                    return(
                                                        <>
                                                            <p className="text-white weight-500">{item?.user_address ? `${item?.user_address.substring(0, 4)}...${item?.user_address.substring(item?.user_address.length - 4)}` : "---"}</p>
                                                        </>
                                                    )
                                                }):(
                                                    <><Empty /></>
                                                )}
                                                
                                            </div>
                                        </div>
                                        <div className="leaderdoard-ref-info">
                                            <Row gutter={30}>
                                                <Col xs={24} sm={12}>
                                                    <div className="ref-info">
                                                        <div className="num">{refCode?.total_users ? refCode?.total_users : 0}</div>
                                                        <div className="desc">Invited Friends</div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={12}>
                                                    <div className="ref-info">
                                                        <div className="num">{refCode?.points ? refCode?.points : 0}</div>
                                                        <div className="desc">Your Points</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="leaderdoard-ref mt-15">
                                                <p className="text-white mt-0">When friends stake using your Invitation code, you'll receive x3 points, friends receive x2 points on their first stake day.</p>

                                                <p className="text-green mt-0 mb-0">Want to top leaderboard with points?</p>
                                                <p className="text-white mt-0">Create your Invitation code & Invite now to earn extra points.</p>

                                                <div className="">
                                                    <div className="share-info">
                        
                                                        {address ? (
                                                            <div className="border-ref d-flex-mid gap-10">
                                                                <CopyToClipboard onCopy={onCopy} text={refStr}>
                                                                    <div className="text-white text-center font-16">{refStr ? `${refStr.substring(0, 18)}...${refStr.substring(refStr.length - 8)}` : "---"}</div>
                                                                </CopyToClipboard>
                                                                <div className="d-flex-mid gap-10">
                                                                    <CopyToClipboard onCopy={onCopy} text={refStr}>
                                                                        <img className="w-15" src="../images/copy-ref.svg" />
                                                                    </CopyToClipboard>
                                                                    <a className="twitter-share-button" target="_blank"
                                                                        href={`https://x.com/intent/post?text=I've earned stable coin and points everyday on +%40StableHodl, powered by @HeLa_Network.%0A%0AStake for high yields and bonuses with my invite code: ${refCode?.code}%0A%0A&hashtags=HeLaLabs,HeLaEcosystem,StableHodl,StakingRewards`}
                                                                        data-size="small">
                                                                        <img className="w-15" src="../images/x.svg" />
                                                                    </a>
                                                                    
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        ):(
                                                            <div className="mt-15">
                                                                <WagmiConnect open={open} onClose={onClose} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                
                </div>
                <Footer />
            </div>
        
        </>
    );
};
export default Leaderboard;
