
import { Col, message, Tabs, Layout, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import React, { useState, useRef, useEffect } from "react";

const Statistics = (prop:any) => {
    const { campaign, pool, statistics, configApy } = prop;
  

  return (
    <>
        <Row gutter={16}>
            <Col xs={24} sm={8}>
                <div className="apy-bg">
                    <div className="pool-total">
                        <div className="mt-0 mb-10 text-white flex-btw">
                            <span>APY</span>
                            <img src="../images/apy.svg" />
                        </div>
                        <p className="mt-0 mb-0 text-green font-20 weight-700">{ configApy?.avg_apy_str }</p>
                        {/* <div className="mt-15 mb-10 text-white flex-btw">
                            <span>Bonus APY</span>
                        </div>
                        <p className="mt-0 mb-0 text-green font-20 weight-700">{ configApy?.avg_hela_apy_str }</p> */}
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={8}>
                <div className="pool-total">
                    <div className="mt-0 mb-10 text-white flex-btw">
                        <span>Total Staked</span>
                        <img src="../images/staked.svg" />
                    </div>
                    <p className="mt-0 mb-10"><span className="font-20 weight-700 text-white">{ campaign ? new Intl.NumberFormat("ja-JP").format(campaign?.amount) : 0} { " " }</span></p>
                    <p className="mt-0 mb-10 flex-btw">
                        <span className="text-gray-light">USDT:</span>
                        <span className="text-white weight-600">{campaign ? new Intl.NumberFormat("ja-JP").format(campaign?.usdt_amount) : 0}</span>
                    </p>
                    <p className="mt-0 mb-10 flex-btw">
                        <span className="text-gray-light">USDC:</span>
                        <span className="text-white weight-600">{ campaign ? new Intl.NumberFormat("ja-JP").format(campaign?.usdc_amount) : 0 }</span>
                    </p>
                    {campaign && campaign?.type == "HELA" && (
                        <>
                            <p className="mt-0 mb-0 flex-btw">
                                <span className="text-gray-light">HLUSD:</span>
                                <span className="text-white weight-600">{ campaign ? new Intl.NumberFormat("ja-JP").format(campaign?.native_amount) : 0 }</span>
                            </p>
                        </>
                    )}
                    
                </div>
            </Col>
            <Col xs={24} sm={8}>
                <div className="pool-total">
                    <div className="mt-0 mb-10 text-white flex-btw">
                        <span>Number of Stakers</span>
                        <img src="../images/staker.svg" />
                    </div>
                    <p className="font-20 weight-700 text-white">{campaign ? new Intl.NumberFormat("ja-JP").format(campaign?.number_of_stakes) : 0}</p>
                </div>
            </Col>
            
            
        </Row>
    </>
  );
};
export default Statistics;
