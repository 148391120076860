
import { Col, message, Tabs, Steps, Form, Button, Input, Spin, Row } from "antd";



declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };

    

const UserInfo = (prop:any) => {

    const { campaign, userClaimed, claimInfo, stakedInfo, stakeContract, pool } = prop;

    return (
        <>
             <Row gutter={30}>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info height-auto mb-15">
                        <Row gutter={10 }>
                            <Col xs={6} sm={4}>
                                <div className="sum-info-img">
                                    <img src="../images/info1.svg" />
                                </div>
                            </Col>
                            <Col xs={18} sm={20}>
                                <p className="mt-0 text-white weight-600">Stake Balance</p>
                                <p className="text-gray-light flex-btw">
                                    <span>{campaign && campaign?.type == "HELA" ? 'hUSDT':'USDT'}: </span>
                                    <span><span className="weight-500 text-white">{ stakedInfo && userClaimed == false ? new Intl.NumberFormat("ja-JP").format(stakedInfo?.usdt_in_hlusd_amount) : 0}</span> $</span>
                                </p>
                                <p className="text-gray-light flex-btw mb-0">
                                    <span>{campaign && campaign?.type == "HELA" ? 'hUSDC':'USDC'} : </span>
                                    <span>
                                        <span className="weight-500 text-white">{stakedInfo && userClaimed == false ? new Intl.NumberFormat("ja-JP").format(stakedInfo?.usdc_in_hlusd_amount) : 0}</span> $
                                    </span>
                                </p>
                                {campaign && campaign?.type == "HELA" && (
                                    <p className="mb-0 flex-btw text-gray-light">
                                        <span className="text-gray-light">HLUSD:</span>
                                        <span>
                                            <span className="text-white weight-600">{ stakedInfo && userClaimed == false ? new Intl.NumberFormat("ja-JP").format(stakedInfo?.native_in_hlusd_amount) : 0 }</span> $
                                            
                                        </span>
                                        
                                    </p>
                                )}
                            </Col>
                        </Row>
                        
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info height-auto mb-15">
                        <Row gutter={10}>
                            <Col xs={6} sm={4}>
                                <div className="sum-info-img">
                                    <img src="../images/info2.svg" />
                                </div>
                            </Col>
                            <Col xs={18} sm={20}>
                                <p className="mt-0 text-white weight-600">Points</p>
                                <p className="text-white weight-600 mb-0"><span>{ claimInfo && userClaimed == false ? new Intl.NumberFormat("ja-JP").format(claimInfo?.points) : 0}</span></p>
                            </Col>
                        </Row>  
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info height-auto mb-15">
                        <p className="mt-0 text-white weight-600">Rewards</p>
                        <p className="mb-0"><span className="text-white weight-600">{claimInfo && userClaimed == false ? new Intl.NumberFormat("ja-JP").format(claimInfo?.reward) : 0}</span> <span className="text-gray-light">HLUSD</span></p>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="point-sum-info height-auto mb-15">
                        <p className="mt-0 text-white weight-600">Total Claimable</p>
                        <p className="mb-0"><span className="text-white weight-600">{ claimInfo && userClaimed == false && Number(claimInfo?.user_claimable) > 0 ? new Intl.NumberFormat("ja-JP").format(claimInfo?.user_claimable) : 0 }</span> <span className="text-gray-light">HLUSD</span></p>
                    </div>
                </Col>
            </Row>
            
            
            
        </>
    );
};
export default UserInfo;
